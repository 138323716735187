import React from 'react';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

export default function SignUp() {
  
  return (
    <Container>
      <Typography variant="h5">
        Sign up
      </Typography>
    </Container>
  );
}